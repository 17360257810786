import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import Header from '@solid-ui-blocks/Header/Block01'
import WhyChooseUs from '@solid-ui-blocks/Features/Block02'
import Hero from '@solid-ui-blocks/Hero/Block02'
import Team from '@solid-ui-blocks/Hero/Block03'
import Footer from '@solid-ui-blocks/Footer/Block01'
import JoinCompanies from '@solid-ui-blocks/Content/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'

const KdsPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo title='KDS' canonical={'soluciones/kds/'} ld_json={{}} description={'Optimiza tu cocina con nuestro Sistema de Pedidos en Cocina. Muestra pedidos en tiempo real desde cualquier dispositivo en una pantalla'}/>
      <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['contact']} />
      <Header content={content['header']} menuJustify='space-between' />
      <Divider space='5' />
      <Divider space='5' />
      <Hero content={content['hero']} reverse />
      <Divider space='5' />
      <JoinCompanies content={content['text']} />
      <WhyChooseUs content={content['options']} />
      <Divider space='5' />
      <Divider space='5' />
      <Team content={content['team']} />
      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query innerpageKDSPageBlockContent {
    allBlockContent(
      filter: { page: { in: ["soluciones/kds", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default KdsPage
